export function installMercadoPago(publicKey) {
    const url = 'https://sdk.mercadopago.com/js/v2';

    return new Promise((resolve, reject) => {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.id = 'mercado-pago-src';
        s.src = url;

        s.addEventListener('load', () => {
            // eslint-disable-next-line
            window.$mercadoPago = new MercadoPago(publicKey || window.$mpPublicKey, {
                locale: 'pt-BR'
            });
            setTimeout(() => resolve(), 100);
        });

        s.addEventListener('error', (error) => {
            console.log(error);
            reject(new Error('Erro ao carregar o script do MercadoPago: ' + JSON.stringify(error)));
        });

        if (!document.getElementById(s.id)) {
            document.getElementsByTagName('head')[0].appendChild(s);
        } else {
            resolve();
        }
    });
}

export async function getPaymentToken(card) {
    try {
        // await installMercadoPago();
        const { id } = await window.$mercadoPago.createCardToken(card);
        return id;
    } catch (error) {
        console.log(error);
        throw new Error('Falha ao obter o token de pagamento');
    }
}
